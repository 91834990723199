import { Fragment, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useDeleteHomeMember,
  useFetchHomeMembers,
  usePostHomeMember,
} from "src/data/organizations/queries/homeQueries"
import { useFetchMembers } from "src/data/organizations/queries/memberQueries"
import {
  HomeRole,
  IHomeMember,
  IOrganizationMember,
} from "src/data/organizations/types/organizationMemberTypes"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBanner } from "src/ui/MBanner/MBanner"
import { FullscreenView } from "src/ui/Modals/FullscreenView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { debug } from "src/utils/logger"

const MEMBER_PAGE_SIZE = 10

export function HomeAddExistingMembers({ homeId }: { homeId: string }) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { limit, setOffset, offset } = useUrlPager({
    initialLimit: MEMBER_PAGE_SIZE,
  })
  const { org } = useOrganization()
  const [busyMemberId, setBusyMemberId] = useState("")
  const deleteHomeMember = useDeleteHomeMember()
  const postHomeMember = usePostHomeMember()

  const fetchHomeMembers = useFetchHomeMembers({
    orgId: org.id,
    homeId: homeId,
  })
  const homeMembers = fetchHomeMembers.data?.members || []

  const fetchMembers = useFetchMembers({
    orgId: org.id,
    filter: { offset, limit },
  })

  const orgMembers =
    fetchMembers.data?.members.filter(
      (m) => !getAccessLogic({ role: m.role }).hasAdminAccess
    ) || []

  function handleAddMemberClick(id: string) {
    const homeMember = {
      orgId: org.id,
      homeId: homeId,
      memberId: id,
      role: HomeRole.MEMBER,
    }
    setBusyMemberId(id)
    postHomeMember.mutate(homeMember, { onSettled: () => setBusyMemberId("") })
  }

  function handleRemoveMemberClick(id: string) {
    setBusyMemberId(id)
    deleteHomeMember.mutate(
      { orgId: org.id, homeId: homeId, memberId: id },
      {
        onSettled() {
          setBusyMemberId("")
        },
      }
    )
  }

  const rows: JSX.Element[] = orgMembers.map((member) => (
    <Fragment key={member.member_id}>
      <div>
        <MText variant="subtitle">{member.name}</MText>
        <MText variant="body">{member.email}</MText>
      </div>

      <ShowAddMemberAction
        key={member.member_id}
        orgMember={member}
        currentHomeMembers={homeMembers}
        onAddMember={handleAddMemberClick}
        onRemoveMember={handleRemoveMemberClick}
        loading={busyMemberId === member.member_id}
      />
    </Fragment>
  ))

  if (fetchMembers.isError || fetchHomeMembers.isError) {
    return (
      <MBanner type="error">{t(langKeys.failed_something_went_wrong)}</MBanner>
    )
  }

  return (
    <AddHomeBox>
      <MText variant="heading2" marginBottom={spacing.XL}>
        {t(langKeys.organizations_home_member_select_title)}
      </MText>

      {!homeId ? (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      ) : (
        <GridTable
          rows={rows}
          templateColumns="2fr auto"
          loading={fetchMembers.isLoading || fetchHomeMembers.isLoading}
          useFallbackResponsiveMode
        />
      )}
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchMembers.data?.paging?.total_count || 0}
        />
      </PagerWrapper>
      <ActionArea $addTopMargin={rows.length <= limit}>
        <MButtonLegacy
          onClick={() => navigate(Routes.HomeAccess.location(homeId))}
        >
          {t(langKeys.done)}
        </MButtonLegacy>
      </ActionArea>
    </AddHomeBox>
  )
}

function ShowAddMemberAction({
  orgMember,
  currentHomeMembers,
  onAddMember,
  onRemoveMember,
  loading,
}: {
  orgMember: IOrganizationMember
  currentHomeMembers: IHomeMember[]
  onAddMember: (homeId: string, homeName: string) => void
  onRemoveMember: (homeId: string) => void
  loading?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const currentMember = currentHomeMembers.find(
    (member) => member.email === orgMember.email
  )

  if (currentMember) {
    return (
      <div key={orgMember.member_id}>
        <StyledButtonBox>
          <MButtonLegacy
            size="small"
            loading={loading}
            onClick={() => {
              onRemoveMember(currentMember.member_id)
              debug.log("removing", orgMember)
            }}
            color="secondary"
          >
            {t(langKeys.remove)}
          </MButtonLegacy>
        </StyledButtonBox>
      </div>
    )
  }

  return (
    <div key={orgMember.user_id}>
      <StyledButtonBox>
        <MButtonLegacy
          size="small"
          color="secondary"
          loading={loading}
          onClick={() => onAddMember(orgMember.member_id, orgMember.name)}
        >
          {t(langKeys.add)}
        </MButtonLegacy>
      </StyledButtonBox>
    </div>
  )
}

export function HomeAddExistingMembersRoute() {
  const { navigate } = useRouter()

  const { homeId = "" } = useParams<{ homeId?: string }>()
  return (
    <FullscreenView
      onClose={() => navigate(Routes.HomeAccess.location(homeId))}
    >
      <HomeAddExistingMembers homeId={homeId} />
    </FullscreenView>
  )
}

const AddHomeBox = styled.div`
  margin: auto;
  width: 70ch;
`

const ActionArea = styled.div<{ $addTopMargin: boolean }>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => (props.$addTopMargin ? spacing.XL : 0)};
`

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PagerWrapper = styled.div`
  margin-block: ${spacing.XL};
`
